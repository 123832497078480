.toggle-metric {
    opacity: 0;
    transform: translateY(3px);
    transition: all 0.6s ease-out;
}

.metric-item {
    cursor: pointer;
}

.metric-item:hover .toggle-metric {
    opacity: 1;
    transform: translateY(0);
}